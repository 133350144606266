<template>
  <b-container fluid>
    <pageHeader title="Account Lookup"></pageHeader>

    <div class="row no_gutters mt-2">
      <div class="col-4">
        <b-input-group prepend="Account Number" class="">
          <b-form-input v-model='lookupAccount'></b-form-input>
          <b-input-group-append>
            <b-button variant="info" @click="searchForAccount">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <div class="row no_gutters mt-3">
      <div class="col-12">
        <b-table tbody-tr-class='table-info' head-variant='light' :items="accountItems" :fields='accountItemsFields'></b-table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-table striped hover :items="recentBills" :fields="recentBills_fields"></b-table> -->
            <b-table class='smallerFont mb-5' small hover fixed :items="recentBills" :fields='recentBillsFields'>
              <template v-slot:cell(adjustment)="data">
                <span v-html="data.value"></span>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import { getCustomer, getCustomerRecentBills } from "@/apis/customers";

export default {
  name: "AccountLookup",
  components: {
    pageHeader,
  },
  methods: {
    searchForAccount() {
      getCustomer(this.lookupAccount)
        .then(({ data }) => {
          this.accountItems = data;
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Customer could not be retrieved",
            e.response.data.message
          );
        });

      getCustomerRecentBills(this.lookupAccount)
        .then(({ data }) => {
          this.recentBills = data.items;
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Customer bills could not be retrieved",
            e.response.data.message
          );
        });
    }
  },
  data() {
    return {
      lookupAccount: '',
      recentBillsFields: [
        {
          key: 'gl_date',
          label: 'Bill Date',
          sortable: true
        },
        {
          key: 'tier1_allocation',
          label: 'Tier 1'
        },
        {
          key: 'tier2_allocation',
          label: 'Tier 2'
        },
        {
          key: 'tier3_allocation',
          label: 'Tier 3'
        },
        {
          key: 'tier4_allocation',
          label: 'Tier 4'
        },
        {
          key: 'tier5_allocation',
          label: 'Tier 5'
        },
        {
          key: 'total_bill',
          label: 'Total Bill',
          formatter: (value) => {
            if (value){
              return '$'+value.toFixed(2)
            }
          }

        },
        {
          key: 'adjustment',
          label: 'Resulting Credit',
          formatter: (value) => {
            if (value){
              return `<b>$${value.toFixed(2)}</b>`
            }
          }

        },
        {
          key: 'count_of_other_charges',
          label: 'Additional Charges',
          formatter: (value) => {
            if(value === 0){
              return ''
            }
            else{
              return value
            }
          }

        }
      ],
      recentBills: [],

      accountItemsFields: ['order_number',	'service_address',	'street_address',	'zip',	'city',	'cust_name1',	'cust_name2',	'email',	'phone'],
      accountItems: []
    };
  }
}
</script>

<style>

</style>

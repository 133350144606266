import axios from "axios";
import getEnv from '@/utils/env'

const SEARCH_URL = getEnv('VUE_APP_SEARCH_URL');

export function search(params) {
  // limit, query, offset
  const queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  return axios.get(`${SEARCH_URL}?${queryString}`);
}

import axios from "axios";
import getEnv from "@/utils/env";

const CUSTOMER_URL = getEnv("VUE_APP_CUSTOMER_URL");


export function getCustomer(appId) {
  return axios.get(`${CUSTOMER_URL}/${appId}`);
}

export function getCustomerRecentBills(account_number) {
  return axios.get(`${CUSTOMER_URL}/${account_number}/recent_bills`);
}

export function getCustomerRecentCallNotes(account_number) {
  return axios.get(`${CUSTOMER_URL}/${account_number}/recent_call_notes`);
}

export function getCustomerExpectedBillDate(account_number) {
  return axios.get(`${CUSTOMER_URL}/${account_number}/expected_bill_date`);
}

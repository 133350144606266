<!--
  The page header component shows
  PAGE TITLE - SEARCH BAR - USERNAME - LOGOUT BUTTON
-->

<template>
  <b-row class="pb-3">
    <b-col cols="6" style="text-align: left">
      <h1>{{ title }}</h1>
    </b-col>
    <b-col cols="3">
      <custom-search-bar :page-header="true"></custom-search-bar>
    </b-col>
    <b-col cols="3">
      {{ userEmail }}
      <b-button variant="primary" @click="logOut" class="ml-2">LOGOUT</b-button>
    </b-col>
  </b-row>
</template>

<script>
import customSearchBar from "./customSearchBar.vue";
export default {
  name: "pageHeader",
  components: { customSearchBar },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    userEmail() {
      return this.$store.state.auth.user.email;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signIn");
    }
  },
};
</script>

<!--
  Implements search logic, shows results, and redirects on click.
-->

<template>
  <b-input-group class="mb-2">
    <b-form-input
      type="search"
      :placeholder="placeholder || 'Search'"
      v-model="search"
      debounce="750"
      autocomplete="off"
    ></b-form-input>
    <ul id="datalist-ul" v-bind:class="{ active: searchResults.length }">
      <li
        v-for="(option, i) in searchResults"
        :key="i"
        @click="onSearchResultClick(option)"
      >
        {{ option.title }}<br v-if="option.text" />
        {{ option.text }}
      </li>
    </ul>
    <b-input-group-append is-text class="clear" style="border-left: 0">
      <b-icon icon="search"></b-icon>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { search } from "@/apis/search";

export default {
  props: {
    placeholder: {
      type: String,
    },
    pageHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      searchResults: [],
    };
  },
  watch: {
    search(val) {
      this.$emit("onSearchChange", val);

      // only search if component is in page header
      if (this.pageHeader) this.onSearchChange(val);
    },
  },
  methods: {
    onSearchChange(evt) {
      this.searchResults = evt ? [{ title: "Searching..." }] : [];
      if (!evt) return; // Nothing to do

      // Start search, for id and account_number
      search({
        query: evt,
        fields: ["id", "account_number", "name"],
        offset: 0,
        limit: 5,
      })
        .then(({ data }) => {
          const adjustment_applications = data.adjustment_applications
            .map((application) => ({
              title: `#${application.id} - ${application.name}`,
              text: `Status: ${application.state}`,
              type: "adjustment-application",
              value: application,
            }));

          this.searchResults = [].concat(adjustment_applications);
          if (!this.searchResults.length) {
            this.searchResults.push({
              title: `No results found for "${evt}"`,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.searchResults = [];
        });
    },
    onSearchResultClick({ type, value }) {
      this.searchResults = [];

      this.$store.dispatch("ui/openModal", {
        data: value,
        ui: this,
        type,
      });
    },
  },
};
</script>

<style>
#datalist {
  margin: auto;
  position: relative;
  width: 400px;
  color: #000;
}

#datalist-ul.active {
  display: block !important;
}

#datalist-input {
  width: 100%;
  height: 54px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid #ccc;
  outline: 0;
}

#datalist-input:focus {
  border: 1px solid #000;
  outline: 0;
}

#datalist-ul {
  display: none;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 400px;
  top: 100%;
  left: 0;
  list-style: none;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 100;
  border: 2px solid #e9ecdf;
  border-top: none;
}

#datalist-ul li {
  display: block;
  text-align: left;
  padding: 0.8em 1em 0.8em 1em;
  color: #343a40;
  cursor: pointer;
}

#datalist-ul li:hover {
  background: #007bb3;
  color: #fff;
}

input[type="search"] {
  color: unset !important;
  border-color: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
}
</style>
